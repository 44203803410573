<template>
  <div>
    <AddNewInventory
      :is-add-new-category-sidebar-active.sync="isAddNewCategorySideBar"
      :dataProp="dataCategory"
      :isLoading="isLoading"
      :isUpdate="isUpdate"
      @refetch-data="refreshTable"
      @onAdd="handleCreateWarehouse"
      @onUpdate="handleUpdateWarehouse"
      title="kho"
      placeholder="Nhập tên kho"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center justify-between">
              <div class="flex items-center">
                <span style="width: 130px">Tìm kiếm:</span>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Tìm kiếm..."
                />
              </div>
              <b-button variant="primary" @click="isAddNewCategorySideBar = true, isUpdate = false">
                <span class="text-nowrap">Thêm kho</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refCategoriesTable"
          class="position-relative"
          :items="fetchWarehouse"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :busy="isLoading"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Không có dữ liệu hiển thị"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mb-1"></b-spinner>
              <br />
              <strong>Loading...</strong>
            </div>
          </template>
          <!-- Column: Title -->
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <b-link
                class="font-weight-bold d-block text-nowrap"
                @click="showEditBar(data.item)"
              >{{ data.item.title }}</b-link>
            </b-media>
          </template>

          <!-- Column: createdAt -->
          <template #cell(created_at)="data">
            <span class="text-capitalize">{{ formatDate(data.item.created_at) }}</span>
          </template>
          <!-- Column: updatedAt -->
          <template #cell(updatedAt)="data">
            <span class="text-capitalize">{{ formatDate(data.item.updatedAt) }}</span>
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <div class="d-flex justify-content-end">
              <feather-icon
                icon="EditIcon"
                size="22"
                class="text-primary cursor-pointer"
                @click="showEditBar(data.item)"
              />
              <!-- <feather-icon
                icon="Trash2Icon"
                size="22"
                class="text-danger ml-2 cursor-pointer"
                @click="handleDeleteCategory(data.item.id)"
              />-->
            </div>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row class="flex items-center justify-between">
          <b-col
            cols="12"
            class="d-flex flex-1 align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Hiển thị {{ dataMeta.from }} đến {{ dataMeta.to }} của {{ dataMeta.of }}
              mục
            </span>
          </b-col>
          <!-- Per Page -->
          <div cols="12" sm="6" class="flex items-center justify-end flex-1">
            <div>
              <label>Hiển thị</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="[10, 25, 50]"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>mục</label>
            </div>
            <!-- Pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 mr-1 ml-3"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ref, watch, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddNewInventory from "./components/AddNewInventory.vue";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BPagination,
  BOverlay
} from "bootstrap-vue";
import moment from "moment";
import vSelect from "vue-select";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    vSelect,
    AddNewInventory
  },
  methods: {
    handleDeleteCategory(id) {
      this.$bvModal
        .msgBoxConfirm("Bạn có chắc chắn muốn xoá kho này không?", {
          title: "Xoá kho",
          size: "sm",
          okVariant: "primary",
          okTitle: "Xác nhận",
          cancelTitle: "Huỷ",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.deleteWarehouse(id);
          }
        });
    }
  },
  setup() {
    const listWarehouses = ref([]);
    const isUpdate = ref(false);
    const isSortDirDesc = ref(false);
    const sortBy = ref("");
    const toast = useToast();
    const showTable = ref(false);
    const refCategoriesTable = ref(null);
    const dataCategory = ref({});
    const isAddNewCategorySideBar = ref(false);
    const isUpdateCategorySidebarActive = ref(false);
    const currentPage = ref(1);
    const isLoading = ref(false);
    const perPage = ref(10);
    const searchQuery = ref("");
    const timeout = ref(null);
    const totalCategories = ref(0);
    const tableColumns = [
      {
        key: "name",
        label: "Tên kho",
        sortable: false
      },
      { key: "location", label: "Địa chỉ", sortable: false },
      // { key: 'creator', label: 'Người tạo', sortable: false },
      { key: "description", label: "Mô tả", sortable: false },
      {
        key: "created_at",
        label: "Ngày tạo",
        class: "text-right",
        sortable: false
      },
      {
        key: "action",
        label: "",
        sortable: false,
        class: "text-right"
      }
    ];

    watch([currentPage, perPage, searchQuery], () => {
      showTable.value = true;
      clearTimeout(timeout.value);
      timeout.value = setTimeout(() => {
        refetchData();
        showTable.value = false;
      }, 1000);
    });

    const showEditBar = data => {
      dataCategory.value = {
        name: data.name,
        id: data.id,
        address: data.location,
        description: data.description
      };
      isAddNewCategorySideBar.value = true;
      isUpdate.value = true;
    };

    const formatDate = time => {
      return moment(time).format("HH:mm:ss DD/MM/YYYY");
    };

    // const deleteWarehouse = id => {
    //   store
    //     .dispatch('inventory/deleteWarehouse', id)
    //     .then(response => {
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Xoá kho thành công',
    //           icon: 'CheckCircleIcon',
    //           variant: 'success'
    //         }
    //       })
    //     })
    //     .catch(e => {
    //       console.log(e)
    //       toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Có lỗi xảy ra khi lấy dữ liệu',
    //           icon: 'AlertTriangleIcon',
    //           variant: 'danger'
    //         }
    //       })
    //     })
    // }

    const handleCreateWarehouse = async data => {
      const isSuccess = await createWarehouse(data);
      if (isSuccess) {
        isAddNewCategorySideBar.value = false;
        refetchData();
      }
    };

    const handleUpdateWarehouse = async data => {
      const isSuccess = await updateWarehouse(data);
      if (isSuccess) {
        isUpdate.value = false;
        isAddNewCategorySideBar.value = false;
        refetchData();
      }
    };

    const refreshTable = () => {
      showTable.value = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        refetchData();
        showTable.value = false;
      }, 2000);
    };

    const fetchWarehouse = (ctx, callback) => {
      isLoading.value = true;
      store
        .dispatch("inventory/fetchWarehouseList", {
          keyword: searchQuery.value,
          page_size: perPage.value,
          page_num: currentPage.value,
          supplier_id: store.state.authenticate.userLogin.supplierInfo.id,
          sort_by: "id",
          order: "desc"
        })
        .then(response => {
          isLoading.value = false;
          listWarehouses.value = response.data?.data || [];
          totalCategories.value = response.data?.page?.total;
          callback(listWarehouses.value);
        })
        .catch(e => {
          isLoading.value = false;
          console.log(e);
          toast({
            component: ToastificationContent,
            props: {
              title: "Có lỗi xảy ra khi lấy dữ liệu",
              icon: "AlertTriangleIcon",
              variant: "danger"
            }
          });
        });
    };

    const createWarehouse = async data => {
      try {
        isLoading.value = true;
        return await store
          .dispatch("inventory/createWarehouse", {
            name: data.name,
            location: data.address,
            description: data.description,
            supplier_id: store.state.authenticate.userLogin.supplierInfo.id
          })
          .then(response => {
            isLoading.value = false;

            toast({
              component: ToastificationContent,
              props: {
                title: "Thêm kho thành công",
                icon: "CheckCircleIcon",
                variant: "success"
              }
            });

            return true;
          });
      } catch (error) {
        isLoading.value = false;
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi xảy ra khi thêm kho",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
        return false;
      } finally {
        isLoading.value = false;
      }
    };

    const updateWarehouse = async value => {
      try {
        isLoading.value = true;
        const data = {
          name: value.name,
          location: value.address,
          description: value.description
        };
        return await store
          .dispatch(`inventory/updateWarehouse`, {
            id: value.id,
            params: {
              supplier_id: store.state.authenticate.userLogin.supplierInfo.id
            },
            data: data
          })
          .then(response => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Cập nhật kho thành công",
                icon: "CheckCircleIcon",
                variant: "success"
              }
            });

            return true;
          });
      } catch (error) {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi xảy ra khi cập nhật kho",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
        return false;
      } finally {
        isLoading.value = false;
      }
    };

    const dataMeta = computed(() => {
      const localItemsCount = refCategoriesTable.value
        ? refCategoriesTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value
      };
    });

    const refetchData = () => {
      refCategoriesTable.value.refresh();
    };

    return {
      isSortDirDesc,
      sortBy,
      showTable,
      perPage,
      currentPage,
      refCategoriesTable,
      searchQuery,
      dataCategory,
      isUpdateCategorySidebarActive,
      isAddNewCategorySideBar,
      isLoading,
      tableColumns,
      fetchWarehouse,
      totalCategories,
      dataMeta,
      refetchData,
      store,
      toast,
      isUpdate,
      ToastificationContent,
      createWarehouse,
      listWarehouses,
      updateWarehouse,
      refreshTable,
      handleUpdateWarehouse,
      handleCreateWarehouse,
      // handleDeleteCategory,
      formatDate,
      showEditBar
      // deleteWarehouse
    };
  }
};
</script>

<style>
</style>