<template>
  <b-sidebar
    id="add-new-inventory-sidebar"
    :visible="isAddNewCategorySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-category-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Tạo mới {{ title }}</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="onSubmit" @reset.prevent="resetForm">
          <!-- Name -->
          <validation-provider #default="validationTitle" name="name" rules="required">
            <b-form-group :label="`Tên ${title}`">
              <b-form-input
                id="name"
                v-model="params.name"
                autofocus
                :state="getValidationState(validationTitle)"
                :placeholder="placeholder"
              />

              <b-form-invalid-feedback>{{ validationTitle.errors[0]?`Bạn cần điền tên ${title}`:'' }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationAddress" name="address" rules="required">
            <b-form-group label="Địa chỉ">
              <b-form-input
                v-model="params.address"
                :state="getValidationState(validationAddress)"
                placeholder="Nhập địa chỉ"
                id="address"
              />
              <b-form-invalid-feedback>{{ validationAddress.errors[0]?`Bạn cần điền địa chỉ`:'' }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group label="Mô tả">
            <b-form-textarea
              id="description"
              v-model="params.description"
              placeholder="Nhập mô tả"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading || isDisableBtn"
            >{{isUpdate?'Cập nhật':'Tạo'}}</b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >Huỷ</b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, computed } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'isAddNewCategorySidebarActive',
    event: 'update:is-add-new-category-sidebar-active'
  },
  props: {
    isAddNewCategorySidebarActive: {
      type: Boolean,
      required: true
    },
    isUpdate: Boolean,
    isLoading: Boolean,
    dataProp: Object,
    title: String,
    placeholder: String,
    // type: String,
    options: Array
  },
  data() {
    return {
      required
    }
  },
  watch: {
    dataProp: {
      handler(value) {
        this.params = { ...value, view: value.view === 2 ? true : false }
        this.showMessageError = false
      },
      deep: true
    }
  },
  mounted() {
    if (this.isUpdate) {
      this.params = {
        ...this.dataProp,
        address: this.dataProp.location
      }
    } else
      this.params = {
        description: '',
        address: '',
        name: ''
      }
  },
  setup(props, { emit }) {
    const toast = useToast()
    const params = ref({
      description: '',
      address: '',
      name: ''
    })

    const resetuserData = () => {
      params.value = {
        description: '',
        address: '',
        name: ''
      }
    }

    const isDisableBtn = computed(() => {
      return !params.value.name || !params.value.address
    })

    const onSubmit = () => {
      props.isUpdate
        ? emit('onUpdate', { ...params.value, id: props.dataProp.id })
        : emit('onAdd', params.value)
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetuserData
    )

    return {
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      store,
      params,
      isDisableBtn
    }
  }
}
</script>

  <style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-inventory-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
